<template>
  <a
    :href="$customUrlScheme.page('detail', 'url=/stage')"
    class="stage-list-button">
    <Icon
      name="pin"
      color="white02"
      width="16"
      height="16"
      class="stage-list-button__icon" />{{ WORDS.STAGE }}一覧
  </a>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  setup: () => {
    return {
      WORDS
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';
.stage-list-button {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: variables.$white02;
  font-size: 13px;
  text-decoration: none;
  &__icon {
    margin-right: 1px;
  }
}
</style>
