var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "stage-list-button",
      attrs: { href: _vm.$customUrlScheme.page("detail", "url=/stage") },
    },
    [
      _c("Icon", {
        staticClass: "stage-list-button__icon",
        attrs: { name: "pin", color: "white02", width: "16", height: "16" },
      }),
      _vm._v(_vm._s(_vm.WORDS.STAGE) + "一覧 "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }